import { datadogRum } from '@datadog/browser-rum';
import JwtDecode from 'jwt-decode';
import { onLogout } from 'service/gtm';
import { getItem, removeItem } from 'utils/local-storage-helpers';

type DecodedTokenT = { roles: string[]; username: string };

export const getToken = (): {
  token?: string;
  decodedToken?: DecodedTokenT;
} => {
  const token = getItem('token') || '';

  if (token) {
    try {
      const decodedToken: DecodedTokenT = JwtDecode(token);

      return { token, decodedToken };
    } catch (e) {
      removeItem('token');
      onLogout();
      datadogRum.addError(e);
    }
  }

  return {};
};
